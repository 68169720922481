import { Drawer as MuiDrawer, Box } from "@mui/material"
import { DrawerContent } from "./DrawerContent"

interface DrawerProps {
    mobileOpen: boolean
    handleDrawerToggle: () => void
    drawerWidth: number
}

export function Drawer({
    mobileOpen,
    handleDrawerToggle,
    drawerWidth,
}: DrawerProps) {
    return (
        <Box
            component="nav"
            sx={{
                width: { sm: drawerWidth },
                flexShrink: { sm: 0 },
            }}
        >
            <MuiDrawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true,
                }}
                sx={{
                    display: { xs: "block", sm: "none" },
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: drawerWidth,
                    },
                }}
            >
                <DrawerContent onMenuItemClick={handleDrawerToggle} />
            </MuiDrawer>
            <MuiDrawer
                variant="permanent"
                sx={{
                    display: { xs: "none", sm: "block" },
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: drawerWidth,
                    },
                }}
                open
            >
                <DrawerContent />
            </MuiDrawer>
        </Box>
    )
}
