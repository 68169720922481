import { CircularProgress, Fade, styled, Typography, useTheme } from "@mui/material"
// @ts-ignore
import { BookLoader } from "react-awesome-loaders"
import { drawerWidth } from "../Layout/Layout"

const Wrapper = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    transparent: 0.5,
    height: "100vh",
    textAlign: "center",
    position: "fixed",
    width: "100%",
    padding: theme.spacing(2),
    paddingTop: theme.spacing(25),
    zIndex: 99,
    top: 0,
    left: 0,
}))

const CustomBookLoader = styled(BookLoader)(({ theme }) => ({
    margin: "auto",
    width: 195,
}))

interface PageLoaderProps {
    appName: string
    loading: boolean
}

export function PageLoader({ appName, loading }: PageLoaderProps) {
    const theme = useTheme()

    return (
        <Fade
            in={loading}
            style={{
                transitionDelay: loading ? "200ms" : "0ms",
            }}
            unmountOnExit
        >
            <Wrapper
                sx={{
                    ml: { sm: `${drawerWidth}px` },
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                }}
            >
                <CustomBookLoader
                    background={`linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`}
                    shadowColor={theme.palette.grey[600]}
                    desktopSize={"50px"}
                    mobileSize={"50px"}
                    textColor={theme.palette.primary.main}
                    text=""
                />
                <CircularProgress color="primary" size={20} />
                <Typography gutterBottom>
                    <b>{appName}</b>
                </Typography>
                <Typography variant="body2">
                    Die Daten werden geladen und geprüft...
                </Typography>
            </Wrapper>
        </Fade>
    )
}
