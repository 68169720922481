import { Backdrop, Grid, IconButton, useTheme } from "@mui/material"
import { CDSItem, Multipart } from "../../../types"
import { XCircle } from "phosphor-react"
import { Checks } from "./Checks"
import { drawerWidth } from "../../../components/Layout/Layout"
import { SinglePartItemInformation } from "../einteiler/components/SinglePartItemInformation"

interface ItemInformationDialogProps {
    open: boolean
    season?: Multipart
    episode?: CDSItem
    onClose: () => void
    imageOnTop?: boolean
}

export function ItemInformationDialog({
    open,
    onClose,
    episode,
    season,
    imageOnTop,
}: ItemInformationDialogProps) {
    const theme = useTheme()

    return (
        <Backdrop open={open} style={{ marginLeft: drawerWidth, padding: 32 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                    <IconButton onClick={onClose}>
                        <XCircle size={36} color={theme.palette.common.white} />
                    </IconButton>
                </Grid>
                <Grid item xs={12} md={6}>
                    <SinglePartItemInformation
                        item={episode}
                        imageOnTop={imageOnTop}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Checks season={season} episode={episode} />
                </Grid>
            </Grid>
        </Backdrop>
    )
}
