import { anchorType } from "react-xarrows"
import { GridSize } from "@mui/material"
import { ReactNode } from "react"

export enum VoiceApp {
    HSS = "HSS",
    MAUS = "MAUS",
    ROCKPALAST = "ROCKPALAST",
    "KANDIDATENCHECK" = "KANDIDATENCHECK",
    "WDR2" = "WDR2",
    "WDR3" = "WDR3",
}

export interface Validation {
    [key: string]: {
        successfully: boolean
        title: string
    }
}

export type CDSItem = {
    id: string
    duration: number
    image: string
    season?: number | null
    episode?: number | null
    isMultipart: boolean
    title: string
    smartSpeakerTitle: string
    teaser: string
    smartSpeakerShort: string
    url: string
    seriesId: string
    validTo: number
    filterTags: string[]
    validation?: Validation
}

export interface Multipart {
    title: string
    seasons: number
    episodes: number
    items: CDSItem[]
    validation?: Validation
}

export interface CDSResponse {
    multiparts: Multipart[]
    singleparts: CDSItem[]
}

export interface Message {
    open: boolean
    severity: "error" | "success" | "info" | "warning"
    text: string
}

export interface MausCDSItem {
    id: string
    media: {
        text: string
        audio: string
        validation?: Validation
    }[]
    date: number
    tags: string[]
    validation?: Validation
}

export interface RockpalastItem {
    id: string
    filterTags: string[]
    duration: number
    image: string
    smartSpeakerShort: string
    smartSpeakerTitle: string
    teaser: string
    title: string
    url: string
    validTo: number
    validation?: Validation
}

export interface CandyCheckItem {
    id: string
    age: string
    audioUrl: string
    city: string
    firstname: string
    image: string
    lastname: string
    occupation: string
    party: string
    videoUrl: string
    winner: boolean
    teilgenommen: boolean
    wahlkreis: string
    validation?: Validation
}

export interface QuestionConfigEntry {
    id: string
    prefix: string
    suffix: string
    primary: boolean
    size: GridSize
    contentSize: GridSize
    textSlice?: number
    justifyContent?: any | undefined
    arrow: {
        arrowLabel: string
        endArrow?: string
        endAnchor?: anchorType
        startAnchor?: anchorType
        style?: any
    }[]
}

export interface Status {
    status: string
    message: string
    progress: number
}

export interface Filter {
    tags: string[]
}

export type Entity = {
    end_pos: number
    start_pos: number
    entity: string
    text: string
    score: number
}

export type SkillNotificationResponse = {
    voiceProduct: string
    stage: string
    notifications: SkillNotification[]
}

export type SkillNotification = {
    id?: string
    notificationDate: Date
    eventDate?: Date
    name: string
    title: string
    type: string
    voiceProduct?: string
}

export type RouteEntry = {
    path: string
    title: string
    icon: string
    component: ReactNode
}

export type SearchLog = {
    id: string
    timestamp: string
    searchValue: string
    entity: string
    score: number
    text: string
}
