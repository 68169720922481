import {
    Avatar,
    Card,
    CardContent,
    Chip,
    Grid,
    Typography,
    useTheme,
} from "@mui/material"
import { useContext } from "react"
import ProductContext from "../../contexts/ProductContext"
import { isCDSItemValid } from "../../helper/checks"
import { getAmountOfTags } from "../../helper/tags"
import { ChipContainer } from "../hss/HssContainer"

export function RockpalastContainer() {
    const theme = useTheme()

    const { productData } = useContext(ProductContext)

    const concerts = Array.isArray(productData)
        ? productData?.filter((item) => item.filterTags?.includes("Konzert"))
        : []

    const festivals = Array.isArray(productData)
        ? productData?.filter((item) => item.filterTags?.includes("Festival"))
        : []

    const interviews = Array.isArray(productData)
        ? productData?.filter((item) => item.filterTags?.includes("Interview"))
        : []

    const data = [
        { items: concerts, type: "Konzerte" },
        { items: festivals, type: "Festivals" },
        { items: interviews, type: "Interviews" },
    ]

    return (
        <Grid container spacing={3}>
            {data?.map((d, index) => (
                <Grid item xs={12} md={4} key={index}>
                    <Card sx={{ textAlign: "center" }}>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Typography variant="h2">
                                        {d.items?.length}
                                    </Typography>
                                    <Typography>{d.type}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography
                                        variant="h2"
                                        sx={{
                                            color:
                                                d?.items?.filter(
                                                    (item) => !isCDSItemValid(item)
                                                ).length === 0
                                                    ? theme.palette.success.main
                                                    : theme.palette.error.main,
                                        }}
                                    >
                                        {
                                            d.items?.filter(
                                                (item) => !isCDSItemValid(item)
                                            )?.length
                                        }
                                    </Typography>
                                    <Typography>Fehler</Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
            {data?.map((d, index) => (
                <Grid item xs={12} md={4} key={index}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                {d.type.slice(0, d.type.length - 1)} Tags
                            </Typography>
                            <ChipContainer>
                                {Object.keys(getAmountOfTags(d.items)).map((key) => (
                                    <Chip
                                        key={key}
                                        label={key}
                                        avatar={
                                            <Avatar>
                                                {getAmountOfTags(d.items)[key]}
                                            </Avatar>
                                        }
                                    />
                                ))}
                            </ChipContainer>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    )
}
