import { Card, CardContent, Grid, Typography, styled } from "@mui/material"
import React from "react"
import MuiAudioPlayer from "mui-audio-player-plus"

const Image = styled("img")(({ theme }) => ({
    width: "100%",
    height: "100%",
    borderRadius: theme.spacing(2),
}))

const Flex = styled("div")(({ theme }) => ({
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: theme.spacing(2),
}))

const AudioPlayer = styled(MuiAudioPlayer)(({ theme }) => ({
    borderRadius: `${theme.spacing(1)} !important`,
}))

interface CandidateInformationProps {
    candidate?: any
}

export function CandidateInformation({ candidate }: CandidateInformationProps) {
    if (!candidate) {
        return null
    }

    return (
        <Card sx={{ height: "100%" }}>
            <CardContent sx={{ height: "100%" }}>
                <Grid container spacing={2} rowSpacing={3}>
                    <Grid item xs={12} md={6}>
                        <Image
                            src={candidate.image}
                            alt={`${candidate.firstname} - Bild`}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Flex>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            {candidate.firstname}{" "}
                                            {candidate.lastname}, {candidate.party}
                                        </Typography>
                                        <Typography>
                                            Wahlkreis: {candidate.wahlkreis}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body2">
                                            Ort: {candidate.city}
                                        </Typography>
                                        <Typography variant="body2">
                                            Beruf: {candidate.occupation || "- / -"}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <AudioPlayer
                                id="inline"
                                display="timeline"
                                inline
                                src={candidate.audioUrl}
                            />
                        </Flex>
                    </Grid>
                    <Grid item xs={12}>
                        <a
                            href={candidate.videoUrl}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Video abspielen
                        </a>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}
