import axios from "axios"

const client = axios.create({
    baseURL: "https://iosyvib33k.execute-api.eu-central-1.amazonaws.com/dev/",
})

export const pushClient = axios.create({
    baseURL: "https://rcpqz2qygi.execute-api.eu-central-1.amazonaws.com/prod/",
})

export default client
