import { ChangeEvent, FormEvent, useEffect, useState } from "react"
import deLocale from "date-fns/locale/de"
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    TextFieldProps,
    Typography,
} from "@mui/material"
import { SkillNotification } from "../../../types"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers"

interface NotificationDialogProps {
    loading: boolean
    open: boolean
    onClose: () => void
    onSubmit: (notification: SkillNotification) => void
    notification?: SkillNotification | null
    isCopyMode: boolean
}

export function NotificationDialog({
    loading,
    open,
    onClose,
    onSubmit,
    notification,
    isCopyMode,
}: NotificationDialogProps) {
    const [name, setName] = useState<string>(notification?.name || "")
    const [title, setTitle] = useState<string>(notification?.title || "")
    const [date, setDate] = useState<Date | null>(
        notification?.notificationDate || null
    )

    const [openPicker, setOpenPicker] = useState(false)

    useEffect(() => {
        if (notification) {
            setName(notification.name)
            setTitle(notification.title)
            setDate(notification.notificationDate)
        }
    }, [notification, open])

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault()
        onSubmit({
            ...notification,
            id: isCopyMode ? undefined : notification?.id,
            name,
            title,
            notificationDate: date || new Date(),
            type: "MessageAlert",
            eventDate: new Date(),
        })
    }

    const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        switch (e.target.name) {
            case "name":
                setName(e.target.value)
                break
            case "title":
                setTitle(e.target.value)
                break
            default:
                break
        }
    }

    const handleDateChange = (date: any) => {
        const newDate = new Date(date || new Date())
        newDate.setMinutes(0)
        newDate.setSeconds(0)
        newDate.setMilliseconds(0)

        setDate(newDate)
    }

    const handleClose = () => {
        setName("")
        setTitle("")
        setDate(null)

        onClose()
    }

    const handleOpenPicker = () => {
        setOpenPicker(!openPicker)
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <form onSubmit={handleSubmit}>
                <DialogTitle>
                    {isCopyMode
                        ? "Skill-Benachrichtigung kopieren"
                        : notification?.id
                        ? "Skill-Benachrichtigung bearbeiten"
                        : "Neue Skill-Benachrichtigung"}
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                name="name"
                                label="Name"
                                fullWidth
                                value={name}
                                required
                                onChange={handleTextChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="title"
                                label="Nachricht"
                                fullWidth
                                value={title}
                                required
                                onChange={handleTextChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                locale={deLocale}
                            >
                                <DateTimePicker
                                    ampm={false}
                                    label="Datum der Benachrichtigung"
                                    views={["day", "hours"]}
                                    value={date}
                                    onChange={handleDateChange}
                                    disablePast
                                    onOpen={handleOpenPicker}
                                    onClose={handleOpenPicker}
                                    open={openPicker}
                                    renderInput={(
                                        params: JSX.IntrinsicAttributes &
                                            TextFieldProps
                                    ) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            onClick={(e) => handleOpenPicker()}
                                            required
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                Neue Nachricht von <b>{name || "[Name]"}</b>:{" "}
                                <b>{title || "[Nachricht]"}</b>
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="inherit">
                        Abbrechen
                    </Button>
                    <Button type="submit" color="primary" disabled={loading}>
                        Speichern
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
