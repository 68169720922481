import { useContext, useEffect, useState } from "react"
import {
    Alert,
    AlertTitle,
    Button,
    Card,
    CardContent,
    Chip,
    Grid,
    TextField,
    Typography,
} from "@mui/material"
import ProductContext from "../../contexts/ProductContext"
import {
    createNotification,
    deleteNotification,
    fetchPushNotifications,
    updateNotification,
    validateAccessToken,
} from "../../services/rest.service"
import { SkillNotification } from "../../types"
import { UpcomingNotifications } from "./components/UpcomingNotifications"
import { PastNotifications } from "./components/PastNotifications"
import { NotificationDialog } from "./components/NotificationDialog"

export function NotificationContainer() {
    const { setMessage, setProductAccessToken, productAccessToken } =
        useContext(ProductContext)

    const [accessTokenInput, setAccessTokenInput] = useState("")
    const [accessTokenInputError, setAccessTokenInputError] = useState(false)
    const [notifications, setNotifications] = useState<SkillNotification[]>([])
    const [currentNotification, setCurrentNotification] =
        useState<SkillNotification | null>(null)
    const [open, setOpen] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)
    const [dialogLoading, setDialogLoading] = useState<boolean>(false)
    const [isCopyMode, setIsCopyMode] = useState<boolean>(false)
    const [product, setProduct] = useState("")
    const [stage, setStage] = useState("")

    useEffect(() => {
        async function fetchData() {
            if (productAccessToken) {
                try {
                    const data = await fetchPushNotifications(productAccessToken)

                    setProduct(data.voiceProduct)
                    setStage(data?.stage?.toUpperCase())
                    setNotifications(data.notifications)
                    setLoading(false)
                } catch (e) {
                    setMessage({
                        open: true,
                        severity: "error",
                        text: "Benachrichtigungen konnten nicht geladen werden!",
                    })
                    setLoading(false)
                }
            }
        }

        fetchData()
    }, [productAccessToken])

    const handleNotification = (notification?: SkillNotification) => {
        setCurrentNotification(open ? null : notification?.id ? notification : null)
        setOpen(!open)
        setIsCopyMode(false)
    }

    const handleCopyNotification = (notification: SkillNotification) => {
        setOpen(!open)
        setCurrentNotification(notification)
        setIsCopyMode(true)
    }

    const handleSubmit = async (notification: SkillNotification) => {
        setDialogLoading(true)

        try {
            if (notification.id) {
                const index = notifications.findIndex(
                    (n) => n.id === notification.id
                )
                if (index !== -1) {
                    const newNotifications = [...notifications]
                    newNotifications[index] = notification

                    await updateNotification(productAccessToken, notification)

                    setNotifications(newNotifications)
                }
            } else {
                setNotifications([...notifications, notification])
                await createNotification(productAccessToken, notification)
            }

            setDialogLoading(false)
        } catch (e) {
            setMessage({
                open: true,
                severity: "error",
                text: "Es ist ein Fehler aufgetreten!",
            })
            setDialogLoading(false)
        }

        handleNotification()
    }

    const handleDeleteNotification = async (notification: SkillNotification) => {
        try {
            const index = notifications.findIndex((n) => n.id === notification.id)
            if (index !== -1) {
                const newNotifications = [...notifications]
                newNotifications.splice(index, 1)

                await deleteNotification(productAccessToken, notification)

                setNotifications(newNotifications)
            }
        } catch (e) {
            setMessage({
                open: true,
                severity: "error",
                text: "Die Skill-Benachrichtigung konnte nicht gelöscht werden!",
            })
        }
    }

    const validateAccessTokenInput = async () => {
        try {
            const data = await validateAccessToken(accessTokenInput)
            setProductAccessToken(accessTokenInput)
            setAccessTokenInputError(false)
        } catch (e) {
            setAccessTokenInputError(true)
        }
    }

    if (!productAccessToken) {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card style={{ maxWidth: 500, margin: "auto", marginTop: 50 }}>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        Skill-Benachrichtigungen
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Alert severity="warning">
                                        <AlertTitle>
                                            Fehlende Berechtigung
                                        </AlertTitle>
                                        Um Skill-Benachrichtigungen erstellen und
                                        verwalten zu können, ist ein Schlüssel
                                        notwendig. Bitte trage diesen ein.
                                    </Alert>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="accessToken"
                                        label="Schlüssel"
                                        fullWidth
                                        value={accessTokenInput}
                                        onChange={(e) =>
                                            setAccessTokenInput(e.target.value)
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        onClick={validateAccessTokenInput}
                                        disabled={!accessTokenInput}
                                    >
                                        Schlüssel prüfen
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    {accessTokenInputError && (
                        <Alert
                            severity="error"
                            style={{ maxWidth: 468, margin: "auto" }}
                        >
                            Der Schlüssel ist nicht gültig! Bitte wende dich an{" "}
                            <a href="mailTo:voice@wdr.de" target="_blank">
                                voice@wdr.de
                            </a>
                        </Alert>
                    )}
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container spacing={2} justifyContent="space-between">
            <Grid item xs={12} md={6}>
                <UpcomingNotifications
                    product={product}
                    stage={stage}
                    loading={loading}
                    onEditNotification={handleNotification}
                    onDeleteNotification={handleDeleteNotification}
                    onCopyNotification={handleCopyNotification}
                    onNewNotificationClick={handleNotification}
                    notifications={notifications
                        .filter(
                            (n) =>
                                new Date(n.notificationDate).getTime() >
                                new Date().getTime()
                        )
                        .sort(
                            (a, b) =>
                                new Date(a?.notificationDate).getTime() -
                                new Date(b?.notificationDate).getTime()
                        )}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <PastNotifications
                    loading={loading}
                    notifications={notifications
                        .filter(
                            (n) =>
                                new Date(n.notificationDate).getTime() <
                                new Date().getTime()
                        )
                        .sort(
                            (a, b) =>
                                new Date(b?.notificationDate).getTime() -
                                new Date(a?.notificationDate).getTime()
                        )}
                />
            </Grid>
            <NotificationDialog
                isCopyMode={isCopyMode}
                notification={currentNotification}
                loading={dialogLoading}
                open={open}
                onClose={handleNotification}
                onSubmit={handleSubmit}
            />
        </Grid>
    )
}
