import { format, isValid } from "date-fns"
import { useContext } from "react"
import ProductContext from "../../../contexts/ProductContext"
import {
    Card,
    CardContent,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useTheme,
} from "@mui/material"
import { TableCell } from "./HistoryContainer"

export const ErrorContainer = () => {
    const theme = useTheme()

    const { errorLogs } = useContext(ProductContext)

    return (
        <Card sx={{ height: "100%" }}>
            <CardContent sx={{ height: "100%" }}>
                <Typography variant="h6" gutterBottom>
                    Fehler (Logs)
                </Typography>
                <TableContainer style={{ height: 260 }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell key="date">Datum</TableCell>
                                <TableCell key="error">Nachricht</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {errorLogs
                                ?.sort(
                                    (a: any, b: any) =>
                                        new Date(b.timestamp).getTime() -
                                        new Date(a.timestamp).getTime()
                                )
                                ?.slice(0, 20)
                                .map((item: any) => (
                                    <TableRow
                                        key={new Date(item.timestamp).getTime()}
                                    >
                                        <TableCell>
                                            <Typography variant="caption">
                                                {isValid(new Date(item?.timestamp))
                                                    ? format(
                                                          new Date(item?.timestamp),
                                                          "dd.MM.yyy HH:mm:ss:SSS"
                                                      )
                                                    : "n/a"}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                variant="caption"
                                                sx={{
                                                    color: theme.palette.error.main,
                                                }}
                                            >
                                                {item.message}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    )
}
