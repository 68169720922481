import { SkillNotificationItem } from "./SkillNotificationItem"
import {
    Card,
    CardContent,
    CircularProgress,
    Grid,
    List,
    Typography,
} from "@mui/material"
import { SkillNotification } from "../../../types"
import { AddAlert } from "@mui/icons-material"

interface PastNotificationsProps {
    notifications: SkillNotification[]
    loading: boolean
}

export function PastNotifications({
    notifications,
    loading,
}: PastNotificationsProps) {
    return (
        <Card style={{ height: "calc(100vh - 130px)", overflowY: "scroll" }}>
            <CardContent style={{ height: "100%" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Vergangene</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {loading ? (
                            <div style={{ textAlign: "center", marginTop: 80 }}>
                                <CircularProgress size={50} />
                            </div>
                        ) : (
                            notifications.length < 1 && (
                                <div
                                    style={{
                                        width: "100%",
                                        textAlign: "center",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "stretch",
                                        height: "40vh",
                                    }}
                                >
                                    <AddAlert
                                        style={{
                                            height: 200,
                                            width: 200,
                                            color: "lightgray",
                                            margin: "auto",
                                        }}
                                    />
                                    <Typography variant="body1">
                                        Es wurden noch keine Skill-Benachrichtigungen
                                        gesendet.
                                    </Typography>
                                </div>
                            )
                        )}
                        <List>
                            {notifications?.map((n) => (
                                <SkillNotificationItem notification={n} key={n.id} />
                            ))}
                        </List>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}
