import { Card, CardContent, styled, Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"
import {
    DataGrid as MuiDataGrid,
    GridCallbackDetails,
    GridColDef,
    GridRowParams,
    GridSelectionModel,
} from "@mui/x-data-grid"

const Wrapper = styled(Card)(({ theme }) => ({
    height: "calc(100vh - 130px)",
    width: "100%",
}))

const Content = styled(CardContent)({
    height: "100%",
})

const styles = {
    root: {
        fontSize: 14,
        cursor: "pointer",
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
            outline: "none",
        },
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none",
        },
    },
}

const useStyles = makeStyles(
    (theme: Theme) => ({
        footer: {
            height: theme.spacing(6),
            minHeight: theme.spacing(6),
        },
        cell: {
            padding: `${theme.spacing(0.5)} ${theme.spacing(1)} !important`,
        },
    }),
    { name: "DataGrid" }
)

interface DataGridProps {
    rows: any[]
    columns: GridColDef[]
    loading?: boolean
    onRowClick?: (params: GridRowParams) => void
    selectionModel?: GridSelectionModel
    autoRowHeight?: boolean
    onSelectionModelChange?: (
        selectionModel: GridSelectionModel,
        details: GridCallbackDetails
    ) => void
}

export function DataGrid({
    onRowClick,
    columns,
    rows,
    loading,
    selectionModel,
    autoRowHeight,
    onSelectionModelChange,
}: DataGridProps) {
    const classes = useStyles()

    const handleRowClick = () => {}

    return (
        <Wrapper>
            <Content>
                <MuiDataGrid
                    sx={styles.root}
                    rows={rows}
                    classes={{
                        cell: classes.cell,
                        footerContainer: classes.footer,
                    }}
                    columns={columns}
                    hideFooterSelectedRowCount
                    onRowClick={onRowClick}
                    rowHeight={40}
                    pageSize={20}
                    loading={loading}
                    getRowHeight={autoRowHeight ? () => "auto" : undefined}
                    selectionModel={selectionModel}
                    onSelectionModelChange={onSelectionModelChange}
                />
            </Content>
        </Wrapper>
    )
}
