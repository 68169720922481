import { VoiceApp } from "../types"
import { HssContainer } from "../pages/hss/HssContainer"
import {
    Book,
    Books,
    ClipboardText,
    FileSearch,
    Microphone,
    MicrophoneStage,
    PaperPlaneTilt,
    UserFocus,
} from "phosphor-react"
import { RockpalastContainer } from "../pages/rockpalast/RockpalastContainer"
import { SinglePartContainer } from "../pages/hss/einteiler/SinglePartContainer"
import { MultiPartContainer } from "../pages/hss/mehrteiler/MultiPartContainer"
import { SearchContainer } from "../pages/hss/SearchContainer"
import { NotificationContainer } from "../components/Notifications/NotificationContainer"
import { RockpalastItems } from "../pages/rockpalast/RockpalastItems"
import { MausQuestions } from "../pages/maus/MausQuestions"
import { KandidatenCheckContainer } from "../pages/kandidatencheck/KandidatencheckContainer"

export const VOICE_APPS = [
    {
        id: VoiceApp.WDR3,
    },
    {
        id: VoiceApp.HSS,
        title: "WDR Hörspielspeicher",
        image: "https://s3-eu-west-1.amazonaws.com/wdr-hoerspielspeicher-audios/images/background/grafik_7_1200.jpg",
        icon: "https://m.media-amazon.com/images/I/71RwqiiRd5L.png",
        routes: [
            {
                path: "/",
                title: "Übersicht",
                icon: <ClipboardText size={24} />,
                component: <HssContainer />,
            },
            {
                path: "/einteiler",
                title: "Einteiler",
                icon: <Book size={24} />,
                component: <SinglePartContainer />,
            },
            {
                path: "/mehrteiler",
                title: "Mehrteiler",
                icon: <Books size={24} />,
                component: <MultiPartContainer />,
            },
            {
                path: "/auswertung-suche",
                title: "Auswertung Suche",
                icon: <FileSearch size={24} />,
                component: <SearchContainer />,
            },
            {
                path: "/benachrichtigungen",
                title: "Benachrichtigungen",
                icon: <PaperPlaneTilt size={24} />,
                component: <NotificationContainer />,
            },
        ],
    },
    {
        id: VoiceApp.MAUS,
        title: "Die Maus des Tages",
        image: "http://www.wdr.de/alexaconfig/live/assets/DieMaus/alexaskill-dummy-1920x1080_b.jpg",
        icon: "https://m.media-amazon.com/images/I/51xOBl4X83L.png",
        routes: [
            {
                path: "/",
                title: "Übersicht",
                icon: <ClipboardText size={24} />,
                component: <MausQuestions />,
            },
        ],
    },
    {
        id: VoiceApp.ROCKPALAST,
        title: "Rockpalast",
        image: "https://www.wdr.de/alexaconfig/live/assets/Rockpalast/Grafiken/rockpalast_bg.png",
        icon: "https://m.media-amazon.com/images/I/51WK05hm2hL.png",
        routes: [
            {
                path: "/",
                title: "Übersicht",
                icon: <ClipboardText size={24} />,
                component: <RockpalastContainer />,
            },
            {
                path: "/konzerte",
                title: "Konzerte",
                icon: <MicrophoneStage size={24} />,
                component: <RockpalastItems type="Konzert" />,
            },
            {
                path: "/festivals",
                title: "Festivals",
                icon: <MicrophoneStage size={24} />,
                component: <RockpalastItems type="Festival" />,
            },
            {
                path: "/interviews",
                title: "Interviews",
                icon: <Microphone size={24} />,
                component: <RockpalastItems type="Interview" />,
            },
        ],
    },
    {
        id: VoiceApp.KANDIDATENCHECK,
        title: "Kandidat:innen-Check",
        image: "https://voicebackend.wdr.de/alexaconfig/live/assets/Kandidatencheck_2022/grafiken/Voice_Hintergrund_1920x1200.jpg",
        icon: "https://m.media-amazon.com/images/I/51kJn9rekaL.png",
        routes: [
            {
                path: "/",
                title: "Teilgenommen",
                icon: <UserFocus size={24} />,
                component: <KandidatenCheckContainer />,
            },
        ],
    },
    {
        id: VoiceApp.WDR2,
        title: "WDR 2",
        image: "https://www1.wdr.de/radio/player/tva-export/wdr-zwei-174~_v-Podcast.jpg",
        icon: "https://m.media-amazon.com/images/I/71RwqiiRd5L.png",
        routes: [
            {
                path: "/",
                title: "Benachrichtigungen",
                icon: <PaperPlaneTilt size={24} />,
                component: <NotificationContainer />,
            },
        ],
    },
]
