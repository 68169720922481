import { GridColDef, GridRowParams } from "@mui/x-data-grid"
import { useEffect, useMemo, useState } from "react"
import { fetchSearchLogsByProduct } from "../../services/rest.service"
import { SearchLog, VoiceApp } from "../../types"
import { mapSearchData } from "../../helper/hss"
import { DataGrid } from "../../components/DataGrid/DataGrid"
import { Chip, IconButton, Typography } from "@mui/material"
import { format } from "date-fns"
import { DataObject } from "@mui/icons-material"

export function SearchContainer() {
    const [loading, setLoading] = useState(true)
    const [searchLogs, setSearchLogs] = useState<SearchLog[]>([])

    useEffect(() => {
        async function fetch() {
            const data = await fetchSearchLogsByProduct(VoiceApp.HSS)
            if (data) {
                setSearchLogs(mapSearchData(data))
                setLoading(false)
            }
        }

        fetch()
    }, [])

    const handleRowClick = (params: GridRowParams) => {}

    const columns: GridColDef[] = useMemo(
        () => [
            {
                field: "timestamp",
                headerName: "Datum",
                width: 130,
                valueFormatter: (params) =>
                    format(new Date(params?.value), "dd.MM.yyy HH:mm"),
            },
            {
                field: "searchValue",
                headerName: "Suchanfrage",
                flex: 1,
            },
            {
                field: "score",
                headerName: "Score",
                renderCell: (params) =>
                    params.value ? (
                        <Typography
                            variant="overline"
                            color={params.value * 100 > 67 ? "green" : "inherit"}
                        >
                            {(params.value * 100).toFixed(2)} %
                        </Typography>
                    ) : (
                        <div />
                    ),
                width: 80,
            },
            {
                field: "entity",
                headerName: "Kategorie",
                width: 120,
                renderCell: (params) =>
                    params.value ? (
                        <Chip
                            label={params.value}
                            size="small"
                            color={
                                params.value === "TITLE"
                                    ? "primary"
                                    : params.value === "AUTHORS"
                                    ? "secondary"
                                    : "default"
                            }
                        />
                    ) : null,
            },
            {
                field: "text",
                headerName: "Wert",
                flex: 1,
            },
            {
                field: "mode",
                headerName: "Art der Suche",
                width: 180,
            },
            {
                field: "results",
                headerName: "Vorgeschlagener Titel",
                valueFormatter: (params) => params.value?.[0]?.title,
                flex: 1,
            },
            {
                field: "results.api",
                headerName: "API",
                renderCell: (params) => (
                    <a href={params.row?.searchQuery} target="_blank">
                        <IconButton>
                            <DataObject />
                        </IconButton>
                    </a>
                ),
                width: 70,
            },
        ],
        []
    )

    return (
        <DataGrid
            columns={columns}
            rows={searchLogs}
            onRowClick={handleRowClick}
            loading={loading}
        />
    )
}
