import {
    DataGrid,
    GridCallbackDetails,
    GridColDef,
    GridRowParams,
    MuiEvent,
} from "@mui/x-data-grid"
import {
    Card,
    CardContent,
    Chip,
    styled,
    Theme,
    Tooltip,
    useTheme,
} from "@mui/material"
import { CDSItem } from "../../../../types"
import { CheckCircle, XCircle } from "phosphor-react"
import { msToTime } from "../../../../helper/strings"
import { makeStyles } from "@mui/styles"
import { EXCLUDED_TAGS } from "../../../../configs/excludedTags"
import { useMemo } from "react"

const styles = {
    root: {
        fontSize: 14,
        cursor: "pointer",
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
            outline: "none",
        },
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none",
        },
    },
}

const useStyles = makeStyles(
    (theme: Theme) => ({
        footer: {
            height: theme.spacing(6),
            minHeight: theme.spacing(6),
        },
        cell: {
            padding: `${theme.spacing(0.5)} ${theme.spacing(1)} !important`,
        },
    }),
    { name: "EinteilerDataGrid" }
)

const Wrapper = styled(Card)(({ theme }) => ({
    height: "calc(100vh - 130px)",
    width: "100%",
}))

const Content = styled(CardContent)({
    height: "100%",
})

export const ChipContainer = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "start",
    flexWrap: "wrap",
    "& > *": {
        margin: theme.spacing(0.5),
    },
}))

interface EinteilerDataGridProps {
    singleParts: CDSItem[]
    onItemClick: (item: CDSItem) => void
}

export function SinglePartDataGrid({
    singleParts = [],
    onItemClick,
}: EinteilerDataGridProps) {
    const classes = useStyles()
    const theme = useTheme()

    const handleRowClick = (
        params: GridRowParams,
        event: MuiEvent<React.MouseEvent>,
        details: GridCallbackDetails
    ) => {
        const item = singleParts?.filter((s) => s.id === params.id)?.[0]
        onItemClick(item)
    }

    const columns: GridColDef[] = useMemo(
        () => [
            {
                field: "smartSpeakerTitle",
                headerName: "Smart Speaker Titel",
                width: 200,
                renderCell: (params) => <b>{params.value}</b>,
            },
            {
                field: "smartSpeakerShort",
                headerName: "Smart Speaker Short",
                flex: 1,
            },
            {
                field: "filterTags",
                headerName: "Tags",
                width: 250,
                renderCell: (params) => {
                    return (
                        <ChipContainer>
                            {[
                                ...new Set(
                                    params.value
                                        .sort()
                                        .filter(
                                            (tag: string) =>
                                                tag.length > 1 &&
                                                !EXCLUDED_TAGS.includes(
                                                    tag.toLowerCase()
                                                )
                                        )
                                ),
                            ].map((value: any) => (
                                <Chip size="small" label={value} />
                            ))}
                        </ChipContainer>
                    )
                },
            },
            {
                field: "duration",
                headerName: "Dauer",
                width: 75,
                valueFormatter: (params) =>
                    params.value ? msToTime(params.value) : "",
            },
            {
                field: "errors",
                headerName: "Checks",
                width: 75,
                renderCell: (params) => {
                    return Object.keys(params?.row?.validation || {}).filter(
                        (key) => !params?.row?.validation?.[key].successfully
                    ).length > 0 ? (
                        <Tooltip title="Checks sind fehlgeschlagen">
                            <XCircle
                                size={25}
                                color={theme.palette.error.main}
                                style={{ marginLeft: 15 }}
                            />
                        </Tooltip>
                    ) : (
                        <Tooltip title="Alle Checks bestanden">
                            <CheckCircle
                                size={25}
                                color={theme.palette.success.main}
                                style={{ marginLeft: 15 }}
                            />
                        </Tooltip>
                    )
                },
            },
        ],
        []
    )

    return (
        <Wrapper>
            <Content>
                <DataGrid
                    sx={styles.root}
                    rows={singleParts}
                    classes={{
                        cell: classes.cell,
                        footerContainer: classes.footer,
                    }}
                    columns={columns}
                    hideFooterSelectedRowCount
                    onRowClick={handleRowClick}
                    rowHeight={40}
                    pageSize={20}
                    getRowHeight={() => "auto"}
                />
            </Content>
        </Wrapper>
    )
}
