import { VOICE_APPS } from "../configs/voiceApps"
import { Grid } from "@mui/material"
import { ItemCard } from "../components/ItemCard/ItemCard"
import { useNavigate } from "react-router-dom"
import {
    fetchConfigByVoiceApp,
    fetchDataByProduct,
    fetchErrorLogs,
} from "../services/rest.service"
import { useContext } from "react"
import ProductContext from "../contexts/ProductContext"
import { CanceledError } from "axios"

export function SelectionPage() {
    const {
        setLoadingProduct,
        setProduct,
        setProductData,
        setConfig,
        setErrorLogs,
        setError,
    } = useContext(ProductContext)
    const navigate = useNavigate()

    const handleClick = async (id: string) => {
        setLoadingProduct(true)
        setProduct(id?.toUpperCase())

        try {
            const data = await fetchDataByProduct(id)
            const config = await fetchConfigByVoiceApp(id)
            const errorLogs = await fetchErrorLogs(id)

            if (data) {
                setProductData(data)
            }

            if (config) {
                setConfig(config)
            }

            if (errorLogs) {
                setErrorLogs(errorLogs)
            }

            console.log(id, data, config, errorLogs)

            if (data) {
                navigate(`/${id?.toLowerCase()}/`)
            }
        } catch (e) {
            if (e instanceof CanceledError) {
            } else {
                navigate("/error")
                setError(e)
            }
        }

        setLoadingProduct(false)
    }

    return (
        <Grid container spacing={2}>
            {VOICE_APPS.map((app) => (
                <Grid item xs={12} md={4} key={app.id}>
                    <ItemCard onClick={handleClick} app={app} />
                </Grid>
            ))}
        </Grid>
    )
}
