import { CDSItem, SearchLog } from "../types"

export function applySinglePartFilter(items: CDSItem[], filter: any): CDSItem[] {
    if (!items || items.length < 1) {
        return []
    }

    return items.filter((item) => isSubarray(item.filterTags, filter.tags))
}

function isSubarray(master: string[], sub: string[]): boolean {
    if (!sub) {
        return true
    }

    // Smart Speaker and WDR Hörspiel are default tags
    if (sub?.length < 3) {
        return true
    }

    return sub
        .sort((a, b) => a.localeCompare(b))
        .every(
            (
                (i) => (v: any) =>
                    (i =
                        master
                            .map((e) => e.toLowerCase())
                            .sort((a, b) => a.localeCompare(b))
                            .indexOf(v, i) + 1)
            )(0)
        )
}

export function mapSearchData(searchLogs: any[]): SearchLog[] {
    return searchLogs.map((log, index) => {
        let result = { entity: "", score: 0, text: "" }
        let steps = {}

        if (log[2]?.value) {
            result = JSON.parse(log[2]?.value)
        }

        if (log[3]?.value) {
            console.log(log[3]?.value)
            try {
                steps = JSON.parse(log[3]?.value)
            } catch (e) {
                console.error(e, log[3]?.value)
            }
        }

        return {
            id: index?.toString(),
            timestamp: log[0]?.value,
            searchValue: log[1]?.value,
            ...result,
            ...steps,
        }
    })
}
