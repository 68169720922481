import { QuestionConfigEntry } from "../types"

export const QuestionConfig: QuestionConfigEntry[] = [
    {
        id: "question",
        prefix: "Frage: ",
        suffix: "",
        primary: true,
        size: 12,
        contentSize: 10,
        arrow: [
            {
                arrowLabel: "Ja",
            },
        ],
    },
    {
        id: "answer",
        prefix: "",
        suffix: "",
        primary: false,
        size: 12,
        contentSize: 10,
        arrow: [
            {
                arrowLabel: "Ja",
            },
        ],
    },
    {
        id: "answerLong",
        prefix: "",
        suffix: "",
        primary: false,
        size: 12,
        contentSize: 10,
        arrow: [
            {
                arrowLabel: "",
            },
        ],
    },
    {
        id: "bonusQuestion",
        prefix: "Bonus: ",
        suffix: "",
        primary: true,
        size: 12,
        contentSize: 10,
        arrow: [
            {
                arrowLabel: "Ja",
            },
        ],
    },
    {
        id: "bonus",
        prefix: "",
        suffix: "",
        primary: false,
        size: 12,
        contentSize: 10,
        arrow: [
            {
                arrowLabel: "Wahr",
                style: { marginLeft: -55 },
                endAnchor: "right",
            },
            {
                arrowLabel: "Blaubär",
                style: { marginLeft: 80 },
                endAnchor: "left",
            },
        ],
    },
    {
        id: "bonusTrue",
        prefix: "",
        suffix: "",
        primary: false,
        size: 5,
        contentSize: 7,
        textSlice: 20,
        arrow: [
            {
                arrowLabel: "",
                endArrow: "bonusEnd",
                startAnchor: "right",
            },
        ],
    },
    {
        id: "bonusFalse",
        prefix: "",
        suffix: "",
        primary: false,
        size: 5,
        contentSize: 7,
        textSlice: 20,
        justifyContent: "flex-end",
        arrow: [
            {
                arrowLabel: "",
                startAnchor: "left",
            },
        ],
    },
    {
        id: "bonusEnd",
        prefix: "",
        suffix: "",
        primary: false,
        size: 12,
        contentSize: 10,
        arrow: [
            {
                arrowLabel: "Ja",
            },
        ],
    },
]
