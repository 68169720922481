import {
    Button,
    Card,
    CardContent,
    Chip,
    CircularProgress,
    Grid,
    List,
    Typography,
} from "@mui/material"
import { SkillNotificationItem } from "./SkillNotificationItem"
import { Add, AddAlert } from "@mui/icons-material"
import { SkillNotification } from "../../../types"

interface UpcomingNotificationsProps {
    notifications: SkillNotification[]
    onNewNotificationClick: () => void
    onEditNotification: (notification: SkillNotification) => void
    onDeleteNotification: (notification: SkillNotification) => void
    onCopyNotification: (notification: SkillNotification) => void
    loading: boolean
    product: string
    stage: string
}

export function UpcomingNotifications({
    notifications,
    onNewNotificationClick,
    onEditNotification,
    onDeleteNotification,
    onCopyNotification,
    loading,
    product,
    stage,
}: UpcomingNotificationsProps) {
    return (
        <Card style={{ height: "calc(100vh - 130px)" }}>
            <CardContent style={{ height: "100%" }}>
                <Grid
                    container
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item xs>
                        <Typography variant="h6">Bevorstehende</Typography>
                    </Grid>
                    <Grid
                        item
                        xs
                        style={{
                            display: "flex",
                            gap: 8,
                            alignItems: "flex-end",
                        }}
                    >
                        {!loading && (
                            <>
                                <Chip label={product} color="primary" />
                                <Chip label={stage} color="secondary" />
                            </>
                        )}
                    </Grid>
                    <Grid item xs style={{ textAlign: "right" }}>
                        <Button
                            startIcon={<Add />}
                            color="primary"
                            onClick={onNewNotificationClick}
                            disabled={loading}
                        >
                            Neu
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                        {loading ? (
                            <div style={{ textAlign: "center", marginTop: 80 }}>
                                <CircularProgress size={50} />
                            </div>
                        ) : (
                            notifications.length < 1 && (
                                <div
                                    style={{
                                        width: "100%",
                                        textAlign: "center",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "stretch",
                                        height: "40vh",
                                    }}
                                >
                                    <AddAlert
                                        style={{
                                            height: 200,
                                            width: 200,
                                            color: "lightgray",
                                            margin: "auto",
                                        }}
                                    />
                                    <Typography variant="body1">
                                        Es sind keine Skill-Benachrichtigungen
                                        geplant.
                                    </Typography>
                                </div>
                            )
                        )}

                        <List>
                            {notifications?.map((n) => (
                                <SkillNotificationItem
                                    key={n.id}
                                    notification={n}
                                    isEditable
                                    onEditClick={onEditNotification}
                                    onCopyClick={onCopyNotification}
                                    onDeleteClick={onDeleteNotification}
                                />
                            ))}
                        </List>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}
