import {
    fetchConfigByVoiceApp,
    fetchDataByProduct,
    getStatusByProduct,
    updateDataByProduct,
} from "../../services/rest.service"
import { poll } from "poll"
import { useContext, useState } from "react"
import ProductContext from "../../contexts/ProductContext"
import { IconButton, styled, Tooltip, Typography } from "@mui/material"
import { format, isValid } from "date-fns"
import { ArrowsClockwise } from "phosphor-react"
import { UpdateErrorDialog } from "../UpdateErrorDialog/UpdateErrorDialog"

const UpdateWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
    textAlign: "right",
}))

const UpdateLoadingIcon = styled(ArrowsClockwise)(({ theme }) => ({
    color: "orange",
    animation: "rotation 2s infinite linear",
}))

const LoadingText = styled(Typography)(({ theme }) => ({
    display: "block",
    [theme.breakpoints.down("md")]: {
        display: "none",
    },
}))

const LoadingSubText = styled(Typography)(({ theme }) => ({
    display: "block",
    [theme.breakpoints.down("md")]: {
        display: "none",
    },
}))

interface DataUpdateContainerProps {
    voiceApp: any
}

export function DataUpdateContainer({ voiceApp }: DataUpdateContainerProps) {
    const {
        loadingProduct,
        product,
        setStatus,
        status,
        setConfig,
        setProductData,
        config,
    } = useContext(ProductContext)

    const [updateLoading, setUpdateLoading] = useState(false)
    const [error, setError] = useState(false)

    const handleError = () => {
        setError(!error)
    }

    const fetchStatus = async () => {
        if (!product) {
            return
        }

        const tempStatus = await getStatusByProduct(product)

        if (tempStatus?.data?.status === "ERROR") {
            setUpdateLoading(false)
            setError(true)
        }

        if (tempStatus?.data.status === "SUCCESS") {
            try {
                const data = await fetchDataByProduct(product)
                const config = await fetchConfigByVoiceApp(product)

                if (data) {
                    setProductData(data)
                    setConfig(config)
                } else {
                    setConfig(null)
                }
            } catch (e) {
                setConfig(null)
                setError(true)
            }

            setUpdateLoading(false)
        }

        if (!tempStatus || !tempStatus.data) {
            setUpdateLoading(false)
            status.push({
                message: "Es ist ein Fehler aufgetreten!",
                status: "ERROR",
                progress: 100,
            })

            setStatus([...status])
        } else {
            status.push(tempStatus.data)
            setStatus([...status])
        }
    }

    const shouldStopPolling = () => status[status.length - 1].status !== "PROGRESS"

    const handleUpdateClick = async () => {
        if (!product) {
            return
        }

        setUpdateLoading(!updateLoading)

        try {
            const res = await updateDataByProduct(product)

            if (res.success) {
                poll(fetchStatus, 1000, shouldStopPolling)
            }
        } catch (e) {
            setError(true)
        }

        setUpdateLoading(!updateLoading)
    }

    return (
        <>
            {voiceApp?.id && !loadingProduct && !updateLoading && (
                <>
                    <Typography variant="caption" sx={{ mr: 2 }}>
                        {isValid(new Date(config?.lastUpdate))
                            ? format(
                                  new Date(config?.lastUpdate),
                                  "dd.MM.yyy HH:mm"
                              ) + " Uhr"
                            : "Stand: n/a"}
                    </Typography>
                    <Tooltip arrow title="Daten aktualisieren">
                        <IconButton
                            sx={{ mr: 2, color: "white" }}
                            onClick={handleUpdateClick}
                        >
                            <ArrowsClockwise size={25} />
                        </IconButton>
                    </Tooltip>
                </>
            )}
            {updateLoading && (
                <UpdateWrapper>
                    <LoadingText variant="caption">
                        {status[status.length - 1]?.message}
                    </LoadingText>
                    <UpdateLoadingIcon
                        size={25}
                        style={{ color: "orange", marginRight: 24 }}
                    />
                </UpdateWrapper>
            )}
            <UpdateErrorDialog open={error} onClose={handleError} />
        </>
    )
}
