import { Card, CardContent, Grid, Typography, styled, Chip } from "@mui/material"
import { CDSItem } from "../../../../types"
import { msToTime } from "../../../../helper/strings"
import { EXCLUDED_TAGS } from "../../../../configs/excludedTags"
import React from "react"
import { ChipContainer } from "./SinglePartDataGrid"
import MuiAudioPlayer from "mui-audio-player-plus"

const Image = styled("img")(({ theme }) => ({
    width: "100%",
    height: "100%",
    borderRadius: theme.spacing(2),
}))

const Flex = styled("div")(({ theme }) => ({
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: theme.spacing(2),
}))

const AudioPlayer = styled(MuiAudioPlayer)(({ theme }) => ({
    borderRadius: `${theme.spacing(1)} !important`,
}))

interface SinglePartItemInformationProps {
    item?: CDSItem
    imageOnTop?: boolean
}

export function SinglePartItemInformation({
    item,
    imageOnTop,
}: SinglePartItemInformationProps) {
    if (!item || !item.id) {
        return null
    }

    return (
        <Card sx={{ height: "100%" }}>
            <CardContent sx={{ height: "100%" }}>
                <Grid container spacing={2} rowSpacing={3}>
                    <Grid item xs={12} md={imageOnTop ? 12 : 6}>
                        <Image
                            src={item.image}
                            alt={`${item.smartSpeakerTitle} - Bild`}
                        />
                    </Grid>
                    <Grid item xs={12} md={imageOnTop ? 12 : 6}>
                        <Flex>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            {item.smartSpeakerTitle}
                                        </Typography>
                                        <Typography variant="caption">
                                            {item.season
                                                ? `Staffel ${item.season} Folge ${item.episode} `
                                                : ""}
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            {msToTime(item.duration)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{ flexGrow: 1 }}>
                                        <ChipContainer>
                                            {[
                                                ...new Set(
                                                    item.filterTags
                                                        .sort()
                                                        .filter(
                                                            (tag: string) =>
                                                                tag.length > 1 &&
                                                                !EXCLUDED_TAGS.includes(
                                                                    tag.toLowerCase()
                                                                )
                                                        )
                                                ),
                                            ].map((value: any) => (
                                                <Chip
                                                    size="small"
                                                    label={value}
                                                    key={value}
                                                />
                                            ))}
                                        </ChipContainer>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <AudioPlayer
                                id="inline"
                                display="timeline"
                                inline
                                src={item.url}
                            />
                        </Flex>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2">
                            {item.smartSpeakerShort}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}
