import { Backdrop, Grid, IconButton, useTheme } from "@mui/material"
import { drawerWidth } from "../../../components/Layout/Layout"
import { XCircle } from "phosphor-react"
import { Checks } from "../../hss/components/Checks"
import { CandidateInformation } from "./CanididateInformation"

interface CandidateInformationDialogProps {
    candidate: any
    open: boolean
    onClose: () => void
}

export function CandidateInformationDialog({
    candidate,
    open,
    onClose,
}: CandidateInformationDialogProps) {
    const theme = useTheme()

    return (
        <Backdrop open={open} style={{ marginLeft: drawerWidth, padding: 32 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                    <IconButton onClick={onClose}>
                        <XCircle size={36} color={theme.palette.common.white} />
                    </IconButton>
                </Grid>
                <Grid item xs={12} md={6}>
                    <CandidateInformation candidate={candidate} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Checks episode={candidate} />
                </Grid>
            </Grid>
        </Backdrop>
    )
}
