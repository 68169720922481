import { Fade, styled, Typography, useTheme } from "@mui/material"
import { drawerWidth } from "../Layout/Layout"
import { WarningOctagon } from "phosphor-react"

const Wrapper = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    transparent: 0.5,
    width: "100%",
    height: "100vh",
    textAlign: "center",
    position: "fixed",
    padding: theme.spacing(2),
    paddingTop: theme.spacing(20),
    zIndex: 99,
    top: 0,
    left: 0,
    overflow: "auto",
}))

const MailLink = styled("a")(({ theme }) => ({
    color: theme.palette.primary.main,
}))

const CodeWrapper = styled("div")(({ theme }) => ({
    maxWidth: 500,
    textAlign: "left",
    fontSize: 12,
    padding: theme.spacing(2),
    backgroundColor: "lightgray",
    overflow: "hidden",
    borderRadius: theme.spacing(1),
    boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
}))

const CodeBlock = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
}))

interface PageErrorProps {
    appName: string
    error: any
}

export function PageError({ appName, error }: PageErrorProps) {
    const theme = useTheme()

    return (
        <Fade
            in={!!error}
            style={{
                transitionDelay: !!error ? "200ms" : "0ms",
            }}
            unmountOnExit
        >
            <Wrapper
                sx={{
                    ml: { sm: `${drawerWidth}px` },
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                }}
            >
                <WarningOctagon size={100} color={theme.palette.error.main} />
                <Typography gutterBottom>
                    <b>{appName}</b>
                </Typography>
                <Typography variant="body1" color="error">
                    <b>Es ist ein Fehler aufgetreten!</b>
                </Typography>
                <br />
                <Typography variant="body2">
                    Bitte wende dich an das Voice Team
                </Typography>
                <MailLink href="mailTo:voice@wdr.de">voice@wdr.de</MailLink>
                <CodeBlock>
                    <CodeWrapper>{JSON.stringify(error)}</CodeWrapper>
                </CodeBlock>
            </Wrapper>
        </Fade>
    )
}
