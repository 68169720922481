import React, { createContext, PropsWithChildren, useState } from "react"
import { Filter, Message, Status } from "../types"

type ProductContextParams = {
    product: string | null
    productData: any
    loadingProduct: boolean
    message: Message
    status: Status[]
    config: any
    filter: Filter
    errorLogs: any
    error: any
    searchLogs: any
    productAccessToken: string
    setProductData: (product: string) => void
    setProduct: (data: any) => void
    setLoadingProduct: (loading: boolean) => void
    setMessage: (message: Message) => void
    setStatus: (status: Status[]) => void
    setConfig: (config: any) => void
    setFilter: (filter: Filter) => void
    setErrorLogs: (errorLogs: any) => void
    setSearchLogs: (searchLogs: any) => void
    setProductAccessToken: (token: string) => void
    setError: (error: any) => void
}

const ProductContext = createContext<ProductContextParams>({
    product: "",
    productData: {},
    loadingProduct: false,
    message: {
        open: false,
        severity: "success",
        text: "",
    },
    status: [{ status: "", message: "", progress: 0 }],
    config: {},
    filter: {
        tags: [],
    },
    errorLogs: [],
    searchLogs: [],
    productAccessToken: "",
    error: undefined,
    setProductData: () => null,
    setProduct: () => null,
    setLoadingProduct: () => null,
    setMessage: () => null,
    setStatus: () => null,
    setConfig: () => null,
    setFilter: () => null,
    setErrorLogs: () => null,
    setSearchLogs: () => null,
    setProductAccessToken: () => null,
    setError: () => null,
})

export const ProductProvider: React.FunctionComponent<PropsWithChildren> = ({
    children,
}) => {
    const [product, setProduct] = useState<string | null>(null)
    const [productData, setProductData] = useState<any>({})
    const [productAccessToken, setProductAccessToken] = useState("")
    const [loadingProduct, setLoadingProduct] = useState<boolean>(false)
    const [config, setConfig] = useState(null)
    const [errorLogs, setErrorLogs] = useState([])
    const [searchLogs, setSearchLogs] = useState([])
    const [filter, setFilter] = useState<Filter>({ tags: [] })
    const [error, setError] = useState<any>(undefined)
    const [message, setMessage] = useState<Message>({
        open: false,
        severity: "success",
        text: "",
    })
    const [status, setStatus] = useState<Status[]>([])

    return (
        <ProductContext.Provider
            value={{
                productAccessToken,
                setProductAccessToken,
                product,
                productData,
                setProductData,
                setProduct,
                loadingProduct,
                setLoadingProduct,
                setMessage,
                message,
                setStatus,
                status,
                setConfig,
                config,
                setFilter,
                filter,
                errorLogs,
                setErrorLogs,
                searchLogs,
                setSearchLogs,
                error,
                setError,
            }}
        >
            {children}
        </ProductContext.Provider>
    )
}

export default ProductContext
