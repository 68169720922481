import { useContext, useEffect, useMemo, useState } from "react"
import ProductContext from "../../contexts/ProductContext"
import { Grid, Tooltip, useTheme } from "@mui/material"
import { DataGrid } from "../../components/DataGrid/DataGrid"
import { GridColDef, GridRowParams } from "@mui/x-data-grid"
import { CheckCircle, XCircle } from "phosphor-react"
import MausQuestion from "./MausQuestion"
import { MausCDSItem } from "../../types"
import { useNavigate, useSearchParams } from "react-router-dom"

export function MausQuestions() {
    const theme = useTheme()
    const navigate = useNavigate()
    const { productData, loadingProduct } = useContext(ProductContext)
    const [params] = useSearchParams()
    const id = params.get("id")

    const [selectedQuestion, setSelectedQuestion] = useState<
        MausCDSItem | undefined
    >(undefined)

    useEffect(() => {
        if (productData && productData.length > 0) {
            const q =
                productData?.filter((q: MausCDSItem) => q.id === id)?.[0] ||
                productData?.[0]
            setSelectedQuestion(q)
        }
    }, [productData])

    const handleRowClick = (question: GridRowParams) => {
        navigate(`?id=${question.id}`)
        setSelectedQuestion(question.row as unknown as MausCDSItem)
    }

    const columns: GridColDef[] = useMemo(
        () => [
            {
                field: "media",
                headerName: "Frage",
                renderCell: (params) => <b>{params.value?.[0]?.text}</b>,
                flex: 1,
            },
            {
                field: "tags",
                headerName: "Sprecher",
                valueFormatter: (params) =>
                    params.value?.includes("Nina") ? "Nina" : "André",
                width: 85,
            },
            {
                field: "checks",
                headerName: "Checks",
                renderCell: (params) => {
                    return Object.keys(params?.row?.validation || {}).filter(
                        (key) => !params?.row?.validation?.[key].successfully
                    ).length > 0 ? (
                        <Tooltip title="Checks sind fehlgeschlagen">
                            <XCircle
                                size={25}
                                color={theme.palette.error.main}
                                style={{ marginLeft: 15 }}
                            />
                        </Tooltip>
                    ) : (
                        <Tooltip title="Alle Checks bestanden">
                            <CheckCircle
                                size={25}
                                color={theme.palette.success.main}
                                style={{ marginLeft: 15 }}
                            />
                        </Tooltip>
                    )
                },
                width: 75,
            },
        ],
        []
    )

    if (loadingProduct) {
        return null
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <DataGrid
                    rows={productData}
                    columns={columns}
                    selectionModel={[selectedQuestion?.id || ""]}
                    onRowClick={handleRowClick}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <MausQuestion item={selectedQuestion} />
            </Grid>
        </Grid>
    )
}
