import {
    AppBar,
    Box,
    CssBaseline,
    IconButton,
    Toolbar,
    Typography,
    useTheme,
} from "@mui/material"
import { FunctionComponent, PropsWithChildren, useContext, useState } from "react"
import { Drawer } from "./Drawer"
import SkillIcon from "../../assets/skill-icon.png"
import { List } from "phosphor-react"
import ProductContext from "../../contexts/ProductContext"
import { PageLoader } from "../PageLoader/PageLoader"
import { VOICE_APPS } from "../../configs/voiceApps"
import { PageError } from "../PageError/PageError"
import { DataUpdateContainer } from "../DataUpdate/DataUpdateContainer"

export const drawerWidth = 300

export const Layout: FunctionComponent<PropsWithChildren> = ({ children }) => {
    const { loadingProduct, product, error } = useContext(ProductContext)
    const theme = useTheme()
    const [mobileOpen, setMobileOpen] = useState(false)

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    }

    const voiceApp = VOICE_APPS.filter((i) => i.id === product)?.[0]

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: "none" } }}
                    >
                        <List />
                    </IconButton>
                    <img
                        src={voiceApp?.icon || SkillIcon}
                        width={40}
                        alt="Skill icon logo"
                    />
                    <Typography
                        variant="h6"
                        noWrap
                        sx={{ marginLeft: theme.spacing(2), flexGrow: 1 }}
                    >
                        {voiceApp?.title || "Alle Skills"}
                    </Typography>
                    <DataUpdateContainer voiceApp={voiceApp} />
                </Toolbar>
            </AppBar>

            <Drawer
                mobileOpen={mobileOpen}
                handleDrawerToggle={handleDrawerToggle}
                drawerWidth={drawerWidth}
            />
            <Box
                sx={{
                    flexGrow: 1,
                    p: 3,
                    backgroundColor: "#E0E1ED",
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    position: "relative",
                }}
            >
                <Toolbar />
                <PageLoader
                    loading={loadingProduct}
                    appName={
                        VOICE_APPS?.filter((i) => i.id === product)?.[0]?.title || ""
                    }
                />
                <PageError
                    appName={
                        VOICE_APPS?.filter((i) => i.id === product)?.[0]?.title || ""
                    }
                    error={error}
                />
                {children}
            </Box>
        </Box>
    )
}
