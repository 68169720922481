import { CandyCheckItem, CDSItem, Multipart, RockpalastItem } from "../types"

export function isCDSItemValid(
    cdsItem: CDSItem | RockpalastItem | CandyCheckItem
): boolean {
    let isValidReturn = true

    const multipartNotValid =
        Object.keys(cdsItem.validation || {}).filter(
            (key) => !cdsItem.validation?.[key].successfully
        ).length > 0

    if (multipartNotValid) {
        isValidReturn = false
    }

    return isValidReturn
}

export function isValid(multipart: Multipart): boolean {
    let isValidReturn = true

    const multipartNotValid =
        Object.keys(multipart.validation || {}).filter(
            (key) => !multipart.validation?.[key].successfully
        ).length > 0

    if (multipartNotValid) {
        return false
    }

    if (multipart.items) {
        multipart.items.map((item) => {
            const itemNotValid =
                Object.keys(item.validation || {}).filter(
                    (key) => !item.validation?.[key].successfully
                ).length > 0

            if (itemNotValid) {
                isValidReturn = false
            }
        })
    }

    return isValidReturn
}
