import { ButtonBase, Card, styled, Typography } from "@mui/material"

const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: "relative",
    height: 300,
    [theme.breakpoints.down("sm")]: {
        width: "100% !important",
        height: 120,
    },
    "&:hover, &.Mui-focusVisible": {
        zIndex: 1,
        "& .MuiImageBackdrop-root": {
            opacity: 0.15,
        },
        "& .MuiImageMarked-root": {
            opacity: 0,
        },
        "& .MuiTypography-root": {
            border: "4px solid currentColor",
        },
    },
}))

const ImageTitle = styled(Typography)(({ theme }) => ({
    position: "relative",
    padding: theme.spacing(2),
}))

const ImageSrc = styled("span")({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center 40%",
})

const Image = styled("span")(({ theme }) => ({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
}))

const ImageBackdrop = styled("span")(({ theme }) => ({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create("opacity"),
    borderRadius: theme.spacing(2),
}))

const ImageMarked = styled("span")(({ theme }) => ({
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
}))

interface ItemCardProps {
    onClick: (id: string) => void
    app: any
}

export function ItemCard({ onClick, app }: ItemCardProps) {
    return (
        <Card>
            <ImageButton
                focusRipple
                key={app.title}
                onClick={() => onClick(app.id)}
                style={{
                    width: "100%",
                }}
            >
                <ImageSrc
                    style={{
                        borderRadius: 16,
                        backgroundImage: `url(${app.image})`,
                    }}
                />
                <ImageBackdrop className="MuiImageBackdrop-root" />
                <Image>
                    <ImageTitle variant="h6" color="inherit">
                        {app.title}
                        <ImageMarked className="MuiImageMarked-root" />
                    </ImageTitle>
                </Image>
            </ImageButton>
        </Card>
    )
}
