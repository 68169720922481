import {
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    styled,
    Typography,
    List,
} from "@mui/material"
import WDRLogo from "../../assets/wdr-logo.png"
import { VOICE_APPS } from "../../configs/voiceApps"
import SkillIcon from "../../assets/skill-icon.png"
import { Link, useLocation, useNavigate } from "react-router-dom"
import packageJson from "../../../package.json"
import { useContext } from "react"
import ProductContext from "../../contexts/ProductContext"
import {
    axiosController,
    fetchConfigByVoiceApp,
    fetchDataByProduct,
    fetchErrorLogs,
} from "../../services/rest.service"
import { CanceledError } from "axios"

const Wrapper = styled("div")({
    display: "flex",
    flexDirection: "column",
    height: "100%",
})

const Spacer = styled("div")({
    flexGrow: 1,
})

const LogoWrapper = styled("div")(({ theme }) => ({
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
}))

const SectionTitle = styled(Typography)(({ theme }) => ({
    margin: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    fontWeight: "bold",
    display: "block",
}))

const InfoContainer = styled("div")(({ theme }) => ({
    width: "100%",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
    display: "flex",
    justifyContent: "space-between",
}))

const MailLink = styled("a")(({ theme }) => ({
    color: theme.palette.common.white,
}))

interface DrawerContentProps {
    onMenuItemClick?: () => void
}

export function DrawerContent({ onMenuItemClick }: DrawerContentProps) {
    const navigate = useNavigate()
    const {
        product,
        setProduct,
        setError,
        setProductData,
        setLoadingProduct,
        setErrorLogs,
        setConfig,
        loadingProduct,
    } = useContext(ProductContext)
    const location = useLocation()

    const handleAllSkillsClick = () => {
        setLoadingProduct(false)
        axiosController.abort()

        // @ts-ignore
        setProductData(undefined)
        setConfig(undefined)
        setErrorLogs(undefined)
        setError(undefined)

        if (onMenuItemClick) {
            onMenuItemClick()
        }

        setTimeout(() => {
            navigate("/")
            setProduct(undefined)
        }, 200)
    }

    const handleClick = async (id: string) => {
        setError(undefined)
        setLoadingProduct(true)
        setProduct(id?.toUpperCase())

        if (onMenuItemClick) {
            onMenuItemClick()
        }

        try {
            const data = await fetchDataByProduct(id)
            const config = await fetchConfigByVoiceApp(id)
            const errorLogs = await fetchErrorLogs(id)

            if (data) {
                setProductData(data)
            }

            if (config) {
                setConfig(config)
            }

            if (errorLogs) {
                setErrorLogs(errorLogs)
            }

            if (data && config && errorLogs) {
                navigate(`/${id?.toLowerCase()}/`)
            }
        } catch (e) {
            if (e instanceof CanceledError) {
            } else {
                navigate("/error")
                setError(e)
            }
        }

        setLoadingProduct(false)
    }

    const handleSubMenuClick = (id: string, path: string) => {
        if (onMenuItemClick) {
            onMenuItemClick()
        }
        navigate(`${id?.toLowerCase()}/${path}`)
    }

    const voiceApp = VOICE_APPS.filter((i) => i.id === product)?.[0]

    return (
        <Wrapper>
            <LogoWrapper>
                <img src={WDRLogo} width={80} alt="WDR Logo" />
                <Typography variant="body2">Content Check Report</Typography>
            </LogoWrapper>
            <ListItem disablePadding selected={location.pathname === "/"}>
                <ListItemButton
                    component={Link}
                    to={"/"}
                    onClick={handleAllSkillsClick}
                >
                    <ListItemIcon>
                        <img src={SkillIcon} alt={`Alle Skills Icon`} width={30} />
                    </ListItemIcon>
                    <ListItemText primary="Alle Skills" />
                </ListItemButton>
            </ListItem>

            <SectionTitle variant="caption">
                {voiceApp ? voiceApp?.title : "Skill auswählen"}
            </SectionTitle>

            {voiceApp ? (
                <List>
                    {voiceApp?.routes?.map((item) => (
                        <ListItem
                            key={item.path}
                            disablePadding
                            selected={
                                location.pathname ===
                                `/${voiceApp?.id.toLowerCase()}${item.path}`
                            }
                        >
                            <ListItemButton
                                disabled={loadingProduct}
                                onClick={() =>
                                    handleSubMenuClick(voiceApp.id, item.path)
                                }
                            >
                                {item.icon && (
                                    <ListItemIcon style={{ color: "white" }}>
                                        {item.icon}
                                    </ListItemIcon>
                                )}

                                <ListItemText primary={item.title} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            ) : (
                <List>
                    {VOICE_APPS.map((item) => (
                        <ListItem
                            key={item.id}
                            disablePadding
                            selected={
                                location.pathname === `/${item.id.toLowerCase()}`
                            }
                        >
                            <ListItemButton onClick={() => handleClick(item.id)}>
                                <ListItemIcon>
                                    <img
                                        src={item.icon}
                                        alt={`${item.title} Icon`}
                                        width={30}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={item.title} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            )}

            <Spacer />
            <InfoContainer>
                <Typography variant="caption">
                    <b>Voice Team</b>
                </Typography>
                <Typography variant="caption">
                    <MailLink href="mailTo:voice@wdr.de">voice@wdr.de</MailLink>
                </Typography>
                <Typography variant="caption">v{packageJson.version}</Typography>
            </InfoContainer>
        </Wrapper>
    )
}
