import { format } from "date-fns"
import {
    Avatar,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
} from "@mui/material"
import {
    CreateOutlined,
    DeleteForeverOutlined,
    Done,
    FileCopyOutlined,
    Timelapse,
} from "@mui/icons-material"
import { SkillNotification } from "../../../types"

interface SkillNotificationItemProps {
    notification: SkillNotification
    isEditable?: boolean
    onEditClick?: (notification: SkillNotification) => void
    onDeleteClick?: (notification: SkillNotification) => void
    onCopyClick?: (notification: SkillNotification) => void
}

export function SkillNotificationItem({
    notification,
    isEditable,
    onEditClick,
    onDeleteClick,
    onCopyClick,
}: SkillNotificationItemProps) {
    const isInFuture = new Date() < new Date(notification.notificationDate)

    return (
        <ListItem>
            <ListItemAvatar>
                <Avatar
                    style={{ backgroundColor: isInFuture ? "lightblue" : "green" }}
                >
                    {isInFuture ? <Timelapse /> : <Done />}
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                secondary={`Neue Nachricht von ${notification.name}: ${notification.title}`}
                primary={`${isInFuture ? "Wird gesendet am:" : "Gesendet:"} ${format(
                    new Date(notification.notificationDate),
                    "dd.MM.yyyy HH:mm"
                )} Uhr`}
            />
            {isEditable && (
                <ListItemSecondaryAction>
                    <IconButton
                        onClick={() =>
                            onEditClick ? onEditClick(notification) : null
                        }
                    >
                        <CreateOutlined />
                    </IconButton>
                    <IconButton
                        onClick={() =>
                            onCopyClick ? onCopyClick(notification) : null
                        }
                    >
                        <FileCopyOutlined />
                    </IconButton>
                    <IconButton
                        onClick={() =>
                            onDeleteClick ? onDeleteClick(notification) : null
                        }
                    >
                        <DeleteForeverOutlined color="error" />
                    </IconButton>
                </ListItemSecondaryAction>
            )}
        </ListItem>
    )
}
