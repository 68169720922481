import { Route, Routes, useLocation } from "react-router-dom"
import { SelectionPage } from "./pages/SelectionPage"
import { useContext, useEffect } from "react"
import ProductContext from "./contexts/ProductContext"
import {
    fetchConfigByVoiceApp,
    fetchDataByProduct,
    fetchErrorLogs,
} from "./services/rest.service"
import { VOICE_APPS } from "./configs/voiceApps"

function App() {
    const location = useLocation()
    const {
        setProductData,
        setLoadingProduct,
        setConfig,
        setErrorLogs,
        setProduct,
    } = useContext(ProductContext)

    useEffect(() => {
        async function fetch() {
            setLoadingProduct(true)
            const loc = location.pathname?.replace("/", "")
            const id = loc?.slice(
                0,
                loc.includes("/") ? loc.indexOf("/") : loc.length
            )

            setProduct(id?.toUpperCase())

            const data = await fetchDataByProduct(id)
            const config = await fetchConfigByVoiceApp(id)
            const errorLogs = await fetchErrorLogs(id)

            console.log(id, data, config, errorLogs)

            if (data) {
                setProductData(data)
            }

            if (config) {
                setConfig(config)
            }

            if (errorLogs) {
                setErrorLogs(errorLogs)
            }

            setLoadingProduct(false)
        }

        fetch()
    }, [])
    return (
        <Routes>
            <Route path="/" element={<SelectionPage />} />
            {VOICE_APPS?.map((app) =>
                app.routes?.map((route) => (
                    <Route
                        key={route.path}
                        path={`/${app.id?.toLowerCase()}${route.path}`}
                        element={route.component}
                    />
                ))
            )}
        </Routes>
    )
}

export default App
