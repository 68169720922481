import { format, isValid } from "date-fns"
import { useContext } from "react"
import ProductContext from "../../../contexts/ProductContext"
import {
    Card,
    CardContent,
    styled,
    Table,
    TableBody,
    TableCell as MuiTableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material"

export const TableCell = styled(MuiTableCell)(({ theme }) => ({
    padding: theme.spacing(1),
}))

export const HistoryContainer = () => {
    const { config } = useContext(ProductContext)

    return (
        <Card sx={{ height: "100%" }}>
            <CardContent sx={{ height: "100%" }}>
                <Typography variant="h6" gutterBottom>
                    Historie (Aktualisierungen)
                </Typography>
                <TableContainer style={{ height: 260 }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell key="date">Datum</TableCell>
                                <TableCell key="singleparts">Einteiler</TableCell>
                                <TableCell key="singlepartErrors">Fehler</TableCell>
                                <TableCell key="multiparts">Mehrteiler</TableCell>
                                <TableCell key="multipartErrors">Fehler</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {config?.data
                                ?.sort(
                                    (a: any, b: any) =>
                                        new Date(b.date).getTime() -
                                        new Date(a.date).getTime()
                                )
                                ?.slice(0, 20)
                                .map((item: any) => (
                                    <TableRow key={new Date(item.date).getTime()}>
                                        <TableCell>
                                            <Typography variant="caption">
                                                {isValid(new Date(item?.date))
                                                    ? format(
                                                          new Date(item?.date),
                                                          "dd.MM.yyy HH:mm"
                                                      )
                                                    : "n/a"}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>{item.singleparts}</TableCell>
                                        <TableCell>
                                            {item.singlepartErrors}
                                        </TableCell>
                                        <TableCell>{item.multiparts}</TableCell>
                                        <TableCell>{item.multipartErrors}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    )
}
